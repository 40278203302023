import React, { useState, useEffect, useRef } from "react";

import { createClient } from "@supabase/supabase-js";
import { BsWhatsapp } from "react-icons/bs";
import { MdOutlineSms, MdOutlineEmail } from "react-icons/md";
import { FaArrowRight, FaArrowLeft, FaChevronDown } from "react-icons/fa6";

import DWHeader from "./DWHeader";
import DWForm from "./DWForm";

import mobile_hero from "../../img/mobile_heropng.png";
import content from "../../data/content";
import VideoPlayer from "../VideoPlayer";

const DWMobile = ({ scroll, width, content }) => {
  const workflowsData = content?.workflows || [];
  const initialWorkflow = Array.isArray(workflowsData)
    ? workflowsData
    : Object.values(workflowsData);
  const { features } = content;
  const { how_it_works } = content;
  const { benefits } = content;
  const { steps } = content;
  const { testimonials } = content;
  const [wf] = useState([...initialWorkflow, ...initialWorkflow]);
  const testimonialKeys = Object.keys(testimonials || {});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showVideo, setShowVideo] = useState(false);
  const scrollContainerRef = useRef(null);
  const { workflows } = content;
  const [stepSelected, setStepSelected] = useState(1);
  const { groups } = content;
  const [currentGroup, setCurrentGroup] = useState("GCs");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleToggleVideo = () => {
    setShowVideo(!showVideo);
  };

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonialKeys.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialKeys.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (!scrollContainer) return;

    const scrollSpeed = 0.5;
    let animationFrameId;

    const scrollStep = () => {
      if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
        scrollContainer.scrollLeft = 0;
      } else {
        scrollContainer.scrollLeft += scrollSpeed;
      }
      animationFrameId = requestAnimationFrame(scrollStep);
    };

    animationFrameId = requestAnimationFrame(scrollStep);

    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleWorkflowSelect = (key) => {
    setStepSelected(key);
    setDropdownOpen(false);
  };

  return (
    <div className="main bg-white text-black flex flex-col px-1">
      <DWHeader width={width} scroll={scroll} />
      <div className="flex flex-col justify-center w-[100%] px-2 mt-[120px] ">
        <span className="text-[40px] leading-none font-black drop-shadow-[3px_3px_0px_#dbdbdb]">
          {content.hero.title}
        </span>
        <br />
        <span className="my-2 text-2xl">{content.hero.subtitle}</span>
        <button
          className="border-2 border-black rounded-md p-2 mt-4 font-bold text-lg shadow-[5px_5px_0px_0px_#1a202c] hover:bg-amber-400 w-[60%]"
          onClick={() => scroll("footer")}
        >
          Get Started
        </button>
      </div>
      <div className="relative mt-10 mr-8">
        <img
          src={"/frame_2608224_1x.webp"}
          alt="Click to play video"
          className=""
          onClick={handleToggleVideo}
        />
        <div
          className="absolute top-4 right-6 flex items-center bg-white border-2 border-black font-bold shadow-[5px_5px_0px_0px_#1a202c] justify-center text-black hover:bg-amber-400 hover:opacity-100 transition-opacity duration-300 p-2 cursor-pointer"
          onClick={handleToggleVideo}
        >
          Play Video
        </div>
        {showVideo && (
          <VideoPlayer showVideo={showVideo} onToggle={handleToggleVideo} />
        )}
      </div>
      <div className="flex items-center flex-col px-2 mt-10 rounded-lg">
        <div
          className="flex flex-col text-black w-[100%] text-[40px] font-bold px-2 items-center py-6 mt-4"
          id="how-minutes-work"
        >
          <span className="leading-10">{content.subsection.text_black}​ </span>
        </div>
        <div className="flex flex-col" id="every-detail">
          <div
            className="flex justify-start items-center overflow-x-scroll no-scrollbar"
            ref={scrollContainerRef}
            style={{
              width: "100%",
              maxWidth: "90vw",
              overflowY: "hidden",
            }}
          >
            {wf.map((workflow, index) => (
              <div
                key={index}
                id={workflow.id}
                className="border-4 border-black rounded-3xl flex flex-col bg-white shadow-[5px_5px_0px_0px_#1a202c] m-2"
                style={{
                  minWidth: "250px",
                  maxWidth: "350px",
                  height: "350px",
                  flexShrink: 0,
                }}
              >
                <div className="flex items-center justify-between px-4 pt-2 bg-gray-100 rounded-t-3xl border-b-[2px] border-dashed border-black h-[80px]">
                  <div className="flex mr-2 w-[10%]">{workflow.avatar}</div>
                  <h1 className="flex-1 text-lg font-bold text-black">
                    {workflow.title}
                  </h1>
                </div>
                <div>
                  <p className="pl-4 pt-4 text-lg font-bold text-black">
                    Actions
                  </p>
                  {workflow?.actions?.map((action, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-center my-4 bg-gray-100 rounded-full px-4 py-2 text-md text-black mx-2"
                    >
                      {action}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="flex flex-col bg-black rounded-3xl p-4 mt-10"
        id="how-it-works"
      >
        <p className="text-2xl font-bold pb-4 text-white">Workflows for: </p>
        <div>
          <div className="flex flex-row overflow-x-scroll no-scrollbar">
            {groups.map((group, index) => (
              <div
                key={index}
                className={`flex items-center justify-center text-lg font-bold mb-4 mr-4 px-4 rounded-full ${
                  group === currentGroup
                    ? "bg-white text-black"
                    : "hover:text-white text-gray-500 bg-gray-700"
                } cursor-pointer`}
                onClick={() => setCurrentGroup(group)}
              >
                {group}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-row mt-10 max-h-[400px]">
          <div className="w-full max-h-[400px]">
            <div className="relative">
              <button
                type="button"
                className="text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 py-2 px-4 rounded-md shadow-sm text-sm font-medium w-full flex items-center justify-between"
                id="workflow-dropdown"
                aria-haspopup="true"
                aria-expanded={dropdownOpen}
                onClick={handleDropdownToggle}
              >
                {stepSelected
                  ? workflows[stepSelected].title
                  : "Select a Digital Coworker"}
                <span className="ml-2">
                  <FaChevronDown />
                </span>
              </button>

              {dropdownOpen && (
                <div
                  className="origin-top-right absolute right-0 mt-2  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="workflow-dropdown"
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    left: "4px",
                  }}
                >
                  {Object.keys(workflows).map((key) => {
                    const workflow = workflows[key];
                    if (workflow.groups.includes(currentGroup)) {
                      return (
                        <div
                          key={key}
                          onClick={() => handleWorkflowSelect(key)}
                          className={`block px-4 py-2 text-sm ${
                            stepSelected === key
                              ? "bg-gray-100"
                              : "hover:bg-gray-100"
                          }`}
                          role="menuitem"
                        >
                          {workflow.title}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </div>
            <div className="h-[350px] overflow-y-scroll">
              {stepSelected && (
                <div className="mt-4 px-4">
                  <div className="w-[20%] mb-4">
                    {workflows[stepSelected].avatar}
                  </div>

                  <p className="text-md text-gray-200">
                    {workflows[stepSelected].description}
                  </p>
                  <p className="text-md text-gray-200 mt-4 font-bold">
                    Actions:
                  </p>
                  {workflows[stepSelected]?.actions?.map((action, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-center my-4 bg-gray-200 rounded-full px-4 py-2 text-md  text-black mx-2"
                    >
                      {action}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full justify-between items-center my-20 px-4">
        <div className="w-[10%]">
          <FaArrowLeft className="text-[20px]" onClick={prevTestimonial} />
        </div>
        <div className="flex w-[80%]">
          <div className="w-full">
            <p className="text-center text-[28px] font-bold">
              "{testimonials[testimonialKeys[currentIndex]].quote}"
            </p>
            <p className="text-center text-[20px] mt-4 text-gray-500 font-bold">
              - {testimonials[testimonialKeys[currentIndex]].by}
            </p>
          </div>
        </div>
        <div className="flex w-[10%] justify-end">
          <FaArrowRight className="text-[20px]" onClick={nextTestimonial} />
        </div>
      </div>

      <DWForm width={width} content={content} />
    </div>
  );
};

export default DWMobile;
