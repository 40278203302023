// src/components/Blog.js
import { React, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { generateSlug } from "../utils"; // Import the generateSlug function
// import Header from "./Header";
import MeetingsHeader from "./Meetings-site/MeetingsHeader.js";
import DOMPurify from "dompurify";
import ReactDom from "react-dom";
import ReactMarkdown from "react-markdown";
import { fetchData } from "../data/blogs.js";
import MeetingsForm from "./Meetings-site/MeetingsForm.js";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [words, setWords] = useState(0);
  const { blogId, slug } = useParams();
  const [blogIndex, setBlogIndex] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const sectionRefs = useRef([]);
  const [activeSectionIndex, setActiveSectionIndex] = useState(null); // Add state for active section
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    sectionRefs.current = sectionRefs.current.slice(0, blogIndex.length);
  }, [blogIndex]);

  const handleClick = (index) => {
    const section = sectionRefs.current[index];
    const yOffset = -100; // Adjust this value to control the offset
    const y =
      section.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const sectionNames = (sections) =>
    sections
      .filter((section) => section.type === "text")
      .map((section) => [section.name, section.index]);

  const countWords = (text) => {
    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.match(/^[a-zA-Z0-9]+$/))
      .join(" ")
      .split(" ");
    return words.length;
  };

  useEffect(() => {
    async function fetchDataAndSetState() {
      try {
        const fetchedData = await fetchData();
        // Parse the JSON string in each post object
        const parsedData = fetchedData.map((post) => ({
          ...post,
          JSON: JSON.parse(post.JSON),
        }));
        setPosts(parsedData);
        console.log("blogs", parsedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchDataAndSetState();
  }, []);

  useEffect(() => {
    console.log("posts", posts);
    console.log("blogId", blogId);
    if (!posts || !blogId) {
      return;
    }
    setSelectedBlog(posts.find((blog) => blog.id === parseInt(blogId)));
  }, [posts, blogId]);

  useEffect(() => {
    console.log("selectedBlog", selectedBlog);
  }, [selectedBlog]);

  useEffect(() => {
    if (!selectedBlog) {
      return;
    }
    let wordsCount = 0;
    for (let i = 0; i < selectedBlog.JSON.sections.length; i++) {
      if (selectedBlog.JSON.sections[i].type === "text") {
        wordsCount += countWords(selectedBlog.JSON.sections[i].content);
      }
    }

    setWords(wordsCount);
    setBlogIndex(sectionNames(selectedBlog.JSON.sections));
  }, [selectedBlog]);

  useEffect(() => {
    const handleScroll = () => {
      let currentSectionIndex = null;
      for (let i = 0; i < sectionRefs.current.length; i++) {
        const section = sectionRefs.current[i];
        if (section.getBoundingClientRect().top <= 100) {
          // Adjust the threshold if needed
          currentSectionIndex = i;
        } else {
          break;
        }
      }
      setActiveSectionIndex(currentSectionIndex);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (!selectedBlog || generateSlug(selectedBlog.JSON.title) !== slug) {
    return <div>Blog not found.</div>;
  }

  return (
    <>
      <MeetingsHeader width={width} />
      {width > 660 ? (
        <div className="main bg-white py-24 sm:py-40 ">
          <div className="mx-auto  px-6 lg:px-8">
            <div className="mx-auto lg:mx-0 flex flex-col items-center ">
              <div className="text-sm leading-6 flex flex-row gap-4">
                <p className="font-semibold text-gray-900">
                  <span className="absolute inset-0" />

                  {selectedBlog.JSON.author}
                </p>
                <span>|</span>
                <p className="font-semibold text-gray-900">
                  Read time: {Math.round(words / 200)} mins
                </p>
              </div>
              <h1 className="text-3xl text-center font-bold tracking-tight text-gray-900 max-sm:text-3xl mt-6">
                {selectedBlog.JSON.title}
              </h1>
              <h3 className="text-center line-clamp w-[80%] mt-6 max-sm:w-[90%]">
                {selectedBlog.JSON.description}
              </h3>
              <div className="flex items-center mt-6 bg-amber-400 p-2 rounded-2xl">
                <span className="text-xs font-bold ">
                  {selectedBlog.JSON.topic}
                </span>
              </div>

              <img
                src={selectedBlog.JSON.cover_image}
                alt={selectedBlog.JSON.title}
                className="w-[60%] rounded-lg mt-8"
              />

              <div className="grid grid-cols-3 gap-4 mt-8 px-24">
                <div className="text-sm bg-gray-300 mt-4 h-fit p-8 rounded-lg sticky top-32">
                  {blogIndex.map((section, index) => (
                    <p
                      className={`mb-4 cursor-pointer hover:font-bold ${
                        activeSectionIndex === index ? "font-bold" : ""
                      }`} // Conditionally apply font-bold
                      key={index}
                      onClick={() => handleClick(index)}
                    >
                      {section[0]}
                    </p>
                  ))}
                </div>
                <div className=" col-span-2 px-8 mt-2">
                  {selectedBlog.JSON.sections.map((section, index) => (
                    <div
                      key={index}
                      ref={(el) => (sectionRefs.current[index] = el)}
                    >
                      {" "}
                      {/* Assign ref to each section */}
                      <p className="text-2xl font-bold">{section.name}</p>
                      <div>
                        <ReactMarkdown className="markdown">
                          {section.content}
                        </ReactMarkdown>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="main bg-white py-24 sm:py-40 ">
          <div className="mx-auto  px-6 lg:px-8">
            <div className="mx-auto lg:mx-0 flex flex-col items-center ">
              <div className="text-sm leading-6 flex flex-row gap-4">
                <p className="font-semibold text-gray-900">
                  <span className="absolute inset-0" />
                  {selectedBlog.JSON.author}
                </p>
                <span>|</span>
                <p className="font-semibold text-gray-900">
                  Read time: {Math.round(words / 200)} mins
                </p>
              </div>
              <h1 className="text-3xl text-center font-bold tracking-tight text-gray-900 max-sm:text-3xl mt-6">
                {selectedBlog.JSON.title}
              </h1>
              <h3 className="text-center line-clamp w-[80%] mt-6 max-sm:w-[90%]">
                {selectedBlog.JSON.description}
              </h3>
              <div className="flex items-center mt-6 bg-amber-400 p-2 rounded-2xl">
                <span className="text-xs font-bold ">
                  {selectedBlog.JSON.topic}
                </span>
              </div>
              <img
                src={selectedBlog.JSON.cover_image}
                alt={selectedBlog.JSON.title}
                className="w-[90%] rounded-lg mt-8"
              />
              <div className="flex flex-col  mt-8 ">
                {/* <div className="text-sm bg-gray-300 mt-4 h-fit p-8 rounded-lg ">
                  {blogIndex.map((section, index) => (
                    <p
                      className={`mb-4 cursor-pointer hover:font-bold ${
                        activeSectionIndex === index ? "font-bold" : ""
                      }`} // Conditionally apply font-bold
                      key={index}
                      onClick={() => handleClick(index)}
                    >
                      {section[0]}
                    </p>
                  ))}
                </div> */}
                <div className="flex text-sm bg-gray-300 mt-4 h-fit  rounded-lg relative p-2 justify-center mb-4">
                  {" "}
                  {/* Added relative for positioning */}
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="text-black font-medium rounded-lg text-sm  text-center inline-flex items-center w-full" // Added w-full
                    type="button"
                  >
                    {isOpen && activeSectionIndex !== null
                      ? blogIndex[activeSectionIndex][0]
                      : "Select a Section"}
                    <svg
                      className="ml-2 w-4 h-4"
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </button>
                  {isOpen && ( // Conditionally render dropdown
                    <div className="z-10 w-full bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 absolute top-12 left-0 ">
                      <ul className="py-1 text-sm text-gray-700 dark:text-gray-200">
                        {blogIndex.map((section, index) => (
                          <li key={index}>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleClick(index);
                                setIsOpen(false);
                              }} // Close on click
                              className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              {section[0]}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="  px-8 mt-2">
                  {selectedBlog.JSON.sections.map((section, index) => (
                    <div
                      key={index}
                      ref={(el) => (sectionRefs.current[index] = el)}
                    >
                      {" "}
                      {/* Assign ref to each section */}
                      <p className="text-2xl font-bold">{section.name}</p>
                      <div>
                        <ReactMarkdown className="markdown">
                          {section.content}
                        </ReactMarkdown>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <MeetingsForm width={width} />
      </div>
    </>
  );
};

export default Blog;
