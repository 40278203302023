import { React, useState, useEffect, useRef } from "react";

import heroRounded from "../../img/hero-image-rounded.png";
// import content from "../../data/DWData";
import VideoPlayer from "../VideoPlayer";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const DWContent = ({ scroll, aboutRef, content }) => {
  // const pageName = "DW_landing_page";
  // const { features: initialFeatures } = content[pageName]; // Store initial features

  const workflowsData = content?.workflows || []; // Ensure features exist and are an array
  const initialWorkflow = Array.isArray(workflowsData)
    ? workflowsData
    : Object.values(workflowsData); // Convert to array if needed
  const [wf] = useState([...initialWorkflow, ...initialWorkflow]); // Duplicate features for infinite scroll
  const { workflows } = content;
  const scrollContainerRef = useRef(null);
  const { steps } = content;
  const { how_it_works } = content;
  const [stepSelected, setStepSelected] = useState(1);
  const { testimonials } = content;
  const testimonialKeys = Object.keys(testimonials);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const intervalRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const { groups } = content;
  const [currentGroup, setCurrentGroup] = useState("GCs");
  // const [features, setFeatures] = useState(initialFeatures); // State for features

  const handleToggleVideo = () => {
    setShowVideo(!showVideo);
  };

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonialKeys.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialKeys.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (!scrollContainer) return;

    const scrollSpeed = 0.5; // Adjust speed of scrolling
    let animationFrameId;

    const scrollStep = () => {
      if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
        // Reset scroll to the start
        scrollContainer.scrollLeft = 0;
      } else {
        scrollContainer.scrollLeft += scrollSpeed;
      }
      animationFrameId = requestAnimationFrame(scrollStep);
    };

    animationFrameId = requestAnimationFrame(scrollStep);

    return () => cancelAnimationFrame(animationFrameId); // Cleanup on unmount
  }, []);

  // useEffect(() => {
  //   if (!isPaused) {
  //     intervalRef.current = setInterval(() => {
  //       setStepSelected(
  //         (prevIndex) => (prevIndex + 1) % Object.keys(steps).length
  //       );
  //     }, 3000);
  //   } else {
  //     clearInterval(intervalRef.current);
  //   }

  //   return () => clearInterval(intervalRef.current);
  // }, [steps, isPaused]);

  const stepSelection = (index) => {
    setStepSelected(index);
    setIsPaused(true);
  };

  return (
    <div className="border-l border-white  mt-48 text-black font-league flex flex-col px-20  ">
      <div>
        <div className="flex flex-row justify-between">
          <div className=" min-h-[550px] w-3/6 pt-[35px] pl-10 2xl:pt-[120px]">
            <h1 className="text-6xl font-bold mb-2 w-[80%] drop-shadow-[3px_3px_0px_#dbdbdb]">
              {content.hero.title}
              {/* Your Handy Construction Sidekick */}
            </h1>
            <p className="text-3xl w-[80%]">{content.hero.subtitle}</p>
            <button
              className="border-2 border-black p-2 mt-4 font-bold text-lg shadow-[5px_5px_0px_0px_#1a202c] hover:bg-amber-400"
              onClick={() => scroll("footer")}
            >
              Get Started
            </button>
          </div>
          <div className="relative w-3/6">
            <img
              src={"/frame_2608224_1x.webp"}
              alt="Click to play video"
              className="cursor-pointer"
              onClick={handleToggleVideo}
            />
            <div
              className="absolute top-8 right-6 flex items-center bg-white border-2 border-black font-bold shadow-[5px_5px_0px_0px_#1a202c] justify-center  text-black hover:bg-amber-400 hover:opacity-100 transition-opacity duration-300 p-2 cursor-pointer" // Changed to position at top right
              // onClick={handleImageClick}
              onClick={handleToggleVideo}
            >
              Play Video
            </div>
            {showVideo && (
              <VideoPlayer showVideo={showVideo} onToggle={handleToggleVideo} />
            )}
          </div>
        </div>

        <div className="flex w-full  justify-center items-center my-20">
          <span className="text-6xl font-bold ">
            Delegate work to your Digital Coworker
          </span>
        </div>
      </div>

      <div className="flex flex-col " id="every-detail">
        <div
          className="flex justify-start items-center overflow-x-scroll no-scrollbar max-w-[90vw] 2xl:max-w-[70vw] "
          ref={scrollContainerRef}
          style={{
            width: "100%", // Ensure the container does not overflow its parent
            // maxWidth: "90vw", // Prevent the container from exceeding the viewport width
            overflowY: "hidden", // Disable vertical scrolling
          }}
        >
          {wf.map((workflow, index) => (
            <div
              key={index}
              id={workflow.id}
              className="border-4 border-black rounded-3xl flex flex-col bg-white shadow-[5px_5px_0px_0px_#1a202c] m-2"
              style={{
                minWidth: "250px", // Set a fixed card width for consistent size
                maxWidth: "350px", // Prevent excessive growth
                height: "350px", // Set a fixed height
                flexShrink: 0, // Prevent shrinking of cards
              }}
            >
              <div className="flex items-center justify-between px-4 pt-2 bg-gray-100 rounded-t-3xl border-b-[2px] border-dashed border-black h-[80px]">
                <div className="flex mr-2 w-[10%]">{workflow.avatar}</div>
                <h1 className="flex-1 text-lg font-bold text-black">
                  {workflow.title}
                </h1>
                {/* <p className="text-sm">{feature.description}</p> */}
              </div>
              {/* <div className="flex-row my-4 flex justify-center items-center">
                {workflow.image}
              </div> */}
              <div>
                <p className="pl-4 pt-4 text-lg font-bold text-black">
                  Actions
                </p>
                {workflow?.actions?.map((action, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-center my-4 bg-gray-100 rounded-full px-4 py-2 text-md  text-black mx-2"
                  >
                    {action}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="flex flex-col bg-black rounded-3xl p-[70px] mt-20 h-[710px] 2xl:px-[200px]"
        id="how-it-works"
      >
        <p className="text-xl font-bold pb-4 text-white">Workflows for: </p>
        <div>
          <div className="flex flex-row">
            {groups.map((group, index) => (
              <div
                key={index}
                className={`flex items-center justify-center text-2xl font-bold mb-4 mr-6  px-4 rounded-full ${
                  group === currentGroup
                    ? "bg-white text-black"
                    : "hover:text-white text-gray-500 bg-gray-700"
                } cursor-pointer`}
                onClick={() => setCurrentGroup(group)}
              >
                {group}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-row mt-10 max-h-[400px]">
          <div className="w-1/2 max-h-[400px] overflow-y-scroll">
            {Object.keys(workflows).map((key) => {
              const workflow = workflows[key];
              if (workflow.groups.includes(currentGroup)) {
                return (
                  <div
                    key={key}
                    onClick={() => stepSelection(key)}
                    className={`${
                      stepSelected === key ? "text-white" : "text-zinc-600"
                    } mb-4 pr-6 cursor-pointer`}
                  >
                    <span className="text-3xl font-bold hover:text-amber-400">
                      {workflow.title
                        .split(" ")
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                        )
                        .join(" ")}
                    </span>
                    {/* <p
                      className={`text-2xl ${
                        stepSelected === key ? "" : "hidden"
                      }`}
                    >
                      {workflow.description}
                    </p> */}
                  </div>
                );
              }
              return null;
            })}
          </div>

          <div className="w-1/2 pl-6  p-4 rounded-3xl h-[400px]">
            <div className="flex flex-row">
              <div className="w-[15%] ">{workflows[stepSelected]?.avatar}</div>
              <div className="w-[85%] text-lg bg-white rounded-xl p-4 ml-2">
                {workflows[stepSelected]?.description}
                {workflows[stepSelected]?.actions?.map((action, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-center my-4 bg-gray-200 rounded-full px-4 py-2 text-md  text-black mx-2"
                  >
                    {action}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full justify-between items-center my-20 rounded-3xl p-[70px]">
        <div className="w-[10%]">
          <FaArrowLeft
            className="text-5xl text-black cursor-pointer"
            onClick={prevTestimonial}
          />
        </div>
        <div className="flex w-[80%]">
          <div className="w-full">
            <p className="text-center text-5xl font-bold text-black">
              "{testimonials[testimonialKeys[currentIndex]].quote}"
            </p>
            <p className="text-center text-3xl mt-4 text-gray-400 font-bold">
              - {testimonials[testimonialKeys[currentIndex]].by}
            </p>
          </div>
        </div>
        <div className="flex w-[10%] justify-end">
          <FaArrowRight
            className="text-5xl text-black cursor-pointer"
            onClick={nextTestimonial}
          />
        </div>
      </div>
    </div>
  );
};

export default DWContent;
