import { React, useState, useEffect, useRef } from "react";

import Footer from "./components/Footer";

import { scrollToSection } from "./utils";
import DWContent from "./components/DigitalWorkers-site/DWContent";
import DWHeader from "./components/DigitalWorkers-site/DWHeader";
import DWForm from "./components/DigitalWorkers-site/DWForm";
import DWMobile from "./components/DigitalWorkers-site/DWMobile";
import content from "./data/DWData";

const DigitalWorkers = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const aboutRef = useRef(null);
  const pageName = "DW_landing_page";

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {width < 660 ? (
        <div>
          <DWMobile
            scroll={scrollToSection}
            width={width}
            content={content[pageName]}
          />
          <Footer width={width} />
        </div>
      ) : (
        <div className="main bg-white w-full h-full flex justify-center ">
          <div className="2xl:max-w-[1440px]  flex justify-center ">
            <DWHeader
              // scroll={scrollToSection}
              width={width}
            />

            <div className="">
              <div className="">
                <DWContent aboutRef={aboutRef} content={content[pageName]} />
              </div>
              <div id="footer section5 " className="bg-white section ">
                <DWForm width={width} content={content[pageName]} />
                <Footer width={width} />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DigitalWorkers;
