import React from "react";
import { BiSolidTimer } from "react-icons/bi";

import { GiTalk } from "react-icons/gi";
import { TbShieldCheck } from "react-icons/tb";

import howitworks from "../img/how-it-works.png";

import click_record from "../img/click_record.png";
import minutes from "../img/minutes.png";
import tasks_review from "../img/tasks_review.png";
import share from "../img/share.png";
import updates from "../img/updates.png";
import record_gif from "../img/Record Meeting.gif";
import tasks_gif from "../img/adding tasks gif.gif";
import lastword_gif from "../img/last word.gif";
import integrations_gif from "../img/integrations gif.gif";
import details from "../img/minutes_details.png";
import mobileDetails from "../img/multiformat_gif.gif";
import worker_1 from "../img/digital_worker_1.png";
import worker_2 from "../img/digital_worker_2.png";
import worker_3 from "../img/digital_worker_3.png";
import worker_4 from "../img/digital_worker_4.png";
import microsoft from "../img/microsoft-logo.png";
import google from "../img/google-logo.png";
import antler from "../img/antler-logo.png";
import formworks from "../img/formwork-logo.png";

const content = {
  DW_landing_page: {
    hero: {
      title: "Digital Construction Workers",
      subtitle:
        "Our digital construction workers automate admin tasks, freeing your time and your team's time to focus on what matters most: building and growing the business.",
    },
    subsection: {
      text_black: "Delegate work to your Digital Coworker",
      text_yellow:
        "writes minutes, extracts and assigns tasks based on meeting details, and follows up with task owners to ensure everything gets done.",
    },
    // groups: {
    //   1: {
    //     title: "Owners",
    //     description:
    //       "The owners of the project are responsible for ensuring that all tasks are completed and that the project is on track to meet the project's goals.",
    //   },
    //   2: {
    //     title: "GCs",
    //     description:
    //       "GCs are responsible for ensuring that all tasks are completed and that the project is on track to meet the project's goals.",
    //   },
    //   3: {
    //     title: "Trades",
    //     description:
    //       "Trades are responsible for ensuring that all tasks are completed and that the project is on track to meet the project's goals.",
    //   },
    //   4: {
    //     title: "Consultants",
    //     description:
    //       "Consultants are responsible for ensuring that all tasks are completed and that the project is on track to meet the project's goals.",
    //   },
    // },
    groups: ["GCs", "Trades", "Owners", "Consultants"],
    benefits: {
      1: {
        title: "In person or online",
        description:
          "Whether your meetings are held in person or online, Flowlly seamlessly captures and processes all the information and handles your meeting documentation with ease.",
        icon: <GiTalk className="text-[60px] mb-4" />,
      },
      2: {
        title: "Save Time and Reduce Manual Work",
        description:
          "Eliminate the tedious task of manually writing meeting minutes. Flowlly automatically generates detailed minutes, allowing you to focus on more critical aspects of the project.",
        icon: <BiSolidTimer className="text-[60px] mb-4" />,
      },
      3: {
        title: "Increase Accuracy",
        description:
          "Human note-takers can get distracted, miss details, or fail to capture key points. Flowlly listens carefully, capturing every detail of your meetings with precision, ensuring nothing is overlooked or forgotten.",
        icon: <TbShieldCheck className="text-[60px] mb-4" />,
      },
    },
    steps: {
      1: {
        title: "Click Record",
        description:
          "When your meeting starts, just click 'Record,' and Flowlly will capture all the information. It’s that easy.",
        image: <img src={click_record} alt="record" className="w-[100%]" />,
      },
      2: {
        title: "Review Minutes",
        description:
          "Flowlly automatically generates meeting minutes as soon as the meeting is over. Just review them and make sure it's all there. You can always listen to the recording again.",
        image: <img src={minutes} alt="minutes" className="w-[100%]" />,
      },
      3: {
        title: "Review Tasks",
        description:
          "Along with the minutes, Flowlly also generates a list of tasks that need to be completed based on the meeting, assigns owners, and ads them to the task list.",
        image: <img src={tasks_review} alt="tasks" className="w-[100%]" />,
      },
      4: {
        title: "Share Minutes and Tasks",
        description:
          "Share your minutes and tasks with your team by email. And, if you prefer, you can also send them to Procore and Autodesk.",
        image: <img src={share} alt="share" className="w-[100%]" />,
      },
      5: {
        title: "Tasks Follow Up",
        description:
          "Flowlly automatically tracks the progress of tasks with their owners and keeps your task list up to date.",
        image: <img src={updates} alt="follow up" className="w-[100%]" />,
      },
    },
    workflows: {
      1: {
        id: "schedule-management",
        image: (
          <img src={tasks_gif} alt="schedule management" className="w-[100%]" />
        ),
        mobileImage: (
          <img src={tasks_gif} alt="schedule management" className="w-[100%]" />
        ),
        avatar: <img src={worker_2} alt="digital worker" />,
        actions: [
          "Schedules Updates",
          "Progress Tracking",
          "Schedule Reporting",
          "Schedule Analysis",
        ],
        title: "SCHEDULE MANAGEMENT ASSISTANT",
        groups: ["Owners", "GCs", "Trades", "Consultants"],
        description:
          "Hello! I'm the Schedule Management Assistant. I can help you keep your project on track by updating your schedule, tracking progress, and generating reports. I can also analyze your schedule to identify potential delays and risks.",
      },
      2: {
        id: "rfi-writer",
        image: (
          <img
            src={record_gif}
            alt="automated RFI creation"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={record_gif}
            alt="automated RFI creation"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_2} alt="digital worker" />,
        actions: ["Drafting RFIs", "RFI Logging and Tracking", "RFI Analysis"],
        title: "RFI WRITER",
        groups: ["GCs", "Consultants"],
        description:
          "Hello! I'm the RFI Writer. I specialize in drafting RFIs, logging and tracking them, and analyzing the responses. I can help you streamline your RFI process and ensure that all your questions are answered.",
      },
      3: {
        id: "rfi-reviewer",
        image: (
          <img
            src={tasks_gif}
            alt="RFI review and management"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={tasks_gif}
            alt="RFI review and management"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_3} alt="digital worker" />,
        actions: [
          "Completness Check",
          "Duplication Check",
          "Risk Assessment and Prioritization",
        ],
        title: "RFI REVIEWER",
        groups: ["GCs", "Consultants"],
        description:
          "I'm the RFI Reviewer. I can help you ensure that your RFIs are complete, accurate, and free of duplicates. I can also assess the risk associated with each RFI and prioritize them accordingly.",
      },
      4: {
        id: "change-order-writer",
        image: (
          <img
            src={lastword_gif}
            alt="change order drafting"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={lastword_gif}
            alt="change order drafting"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_4} alt="digital worker" />,
        actions: [
          "Change Order Drafting",
          "Documents Gathering",
          "Change Order Logging and Tracking",
          "Change Order Analysis",
        ],
        title: "CHANGE ORDER WRITER",
        groups: ["GCs", "Trades", "Consultants"],
        description:
          "Hi, I'm the Change Order Writer. I can help you draft change orders, gather the necessary documentation, and track the status of your change orders. I can also analyze the impact of change orders on your project.",
      },
      5: {
        id: "change-order-reviewer",
        image: (
          <img
            src={integrations_gif}
            alt="change order review"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={integrations_gif}
            alt="change order review"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_1} alt="digital worker" />,
        actions: [
          "Cost and Scope Verification",
          "Contract Compliance Verification",
          "Impact Assessment",
        ],
        title: "CHANGE ORDER REVIEWER",
        groups: ["Owners", "GCs", "Consultants"],
        description:
          "I'm the Change Order Reviewer. I can help you verify the cost and scope of change orders, ensure that they comply with your contract, and assess their impact on your project.",
      },
      6: {
        id: "risk-management",
        image: (
          <img
            src={integrations_gif}
            alt="risk management"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={integrations_gif}
            alt="risk management"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_1} alt="digital worker" />,
        actions: [
          "Risk Register Tracking",
          "Risk Identification",
          "Risk Mitigation Plan",
        ],
        title: "RISK MANAGEMENT ASSISTANT",
        groups: ["Owners", "GCs", "Trades", "Consultants"],
        description:
          "I'm the Risk Management Assistant. I can help you track risks, identify potential problems, and develop mitigation plans. I can also help you monitor the effectiveness of your risk management strategies.",
      },
      7: {
        id: "daily-log-writer",
        image: (
          <img
            src={lastword_gif}
            alt="daily log generation"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={lastword_gif}
            alt="daily log generation"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_3} alt="digital worker" />,
        actions: [
          "Daily Log Drafting",
          "Images Gathering",
          "Daily Log Upload and Share",
        ],
        title: "DAILY LOG WRITER",
        groups: ["GCs", "Trades"],
        description:
          "I'm the Daily Log Writer. I can help you create daily logs, gather images, and share them with your team. I can also help you track the progress of your project and identify any issues that need to be addressed.",
      },
      8: {
        id: "inspection-report-writer",
        image: (
          <img
            src={integrations_gif}
            alt="inspection report writing"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={integrations_gif}
            alt="inspection report writing"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_4} alt="digital worker" />,
        actions: [
          "Inspection Report Drafting",
          "Check List Generation and Tracking",
          "Issues Logging and Tracking",
        ],
        title: "INSPECTION REPORT WRITER",
        groups: ["Owners", "GCs", "Consultants"],
        description:
          "Hi, I'm the Inspection Report Writer. I can help you draft inspection reports, generate checklists, and track issues. I can also help you ensure that your project meets all the required standards.",
      },
      9: {
        id: "submittals-writer",
        image: (
          <img
            src={tasks_gif}
            alt="submittal management"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={tasks_gif}
            alt="submittal management"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_1} alt="digital worker" />,
        actions: [
          "Submittal Package Preparation",
          "Submittal Logging and Tracking",
        ],
        title: "SUBMITTALS WRITER",
        groups: ["GCs", "Trades"],
        description:
          "I'm the Submittals Writer. I can help you prepare submittal packages, log and track submittals, and ensure that they are approved on time.",
      },
      10: {
        id: "punch-list-assistant",
        image: (
          <img
            src={tasks_gif}
            alt="punch list management"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={tasks_gif}
            alt="punch list management"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_4} alt="digital worker" />,
        actions: [
          "Items Gathering and Categorization",
          "Items Assignment and Tracking",
          "Documentation",
          "Reporting",
        ],
        title: "PUNCH LIST ASSISTANT",
        groups: ["GCs", "Trades"],
        description:
          "Hi, I'm the Punch List Assistant. I can help you gather and categorize punch list items, assign them to the responsible parties, and track their completion. I can also generate reports on the status of your punch list.",
      },
      11: {
        id: "procurement-assistant",
        image: (
          <img
            src={lastword_gif}
            alt="procurement assistance"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={lastword_gif}
            alt="procurement assistance"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_2} alt="digital worker" />,
        actions: [
          "Inventory Analysis",
          "Quote Comparison",
          "Purchase Order Drafting",
          "Order Tracking",
        ],
        title: "PROCUREMENT ASSISTANT",
        groups: ["Owners", "GCs", "Trades"],
        description:
          "Hello! I'm the Procurement Assistant. I can help you analyze your inventory, compare quotes, draft purchase orders, and track orders. I can also help you ensure that you are getting the best possible prices for your materials.",
      },
      12: {
        id: "deliveries-assistant",
        image: (
          <img
            src={integrations_gif}
            alt="delivery assistance"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={integrations_gif}
            alt="delivery assistance"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_3} alt="digital worker" />,
        actions: [
          "Deliveries Scheduling",
          "Deliveries Tracking",
          "Deliveries Details Q&A",
        ],
        title: "DELIVERIES ASSISTANT",
        groups: ["Owners", "GCs", "Trades"],
        description:
          "I'm the Deliveries Assistant. I can help you schedule deliveries, track their status, and answer any questions you may have about your deliveries.",
      },
      13: {
        id: "information-search",
        image: (
          <img
            src={lastword_gif}
            alt="information search"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={lastword_gif}
            alt="information search"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_1} alt="digital worker" />,
        actions: ["Document Retrieval", "Codes and Regulation Lookup"],
        title: "INFORMATION SEARCH ASSISTANT",
        groups: ["Owners", "GCs", "Trades", "Consultants"],
        description:
          "I'm the Information Search Assistant. I can help you find the documents, codes, and regulations you need to complete your project.",
      },
      14: {
        id: "bids-writing-assistant",
        image: (
          <img src={integrations_gif} alt="bid writing" className="w-[100%]" />
        ),
        mobileImage: (
          <img src={integrations_gif} alt="bid writing" className="w-[100%]" />
        ),
        avatar: <img src={worker_2} alt="digital worker" />,
        actions: [
          "Bid Analysis",
          "Document Gathering",
          "Bid Form Completion",
          "Bid Submission and Tracking",
        ],
        title: "BIDS WRITING ASSISTANT",
        groups: ["GCs", "Trades", "Consultants"],
        description:
          "Hello! I'm the Bids Writing Assistant. I can help you analyze bids, gather the necessary documentation, complete bid forms, and submit your bids. I can also track the status of your bids and help you negotiate the best possible price.",
      },
      15: {
        id: "bid-evaluation-assistant",
        image: (
          <img src={tasks_gif} alt="bid evaluation" className="w-[100%]" />
        ),
        mobileImage: (
          <img src={tasks_gif} alt="bid evaluation" className="w-[100%]" />
        ),
        avatar: <img src={worker_3} alt="digital worker" />,
        actions: ["Bid Leveling", "Bid Assessment"],
        title: "BID EVALUATION ASSISTANT",
        groups: ["Owners", "GCs", "Consultants"],
        description:
          "I'm the Bid Evaluation Assistant. I can help you level bids, assess their competitiveness, and select the best bid for your project.",
      },
      16: {
        id: "inventory-management",
        image: (
          <img
            src={lastword_gif}
            alt="inventory management"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={lastword_gif}
            alt="inventory management"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_4} alt="digital worker" />,
        actions: [
          "Inventory Updates",
          "Inventory Analysis",
          "Inventory Tracking",
        ],
        title: "INVENTORY MANAGEMENT ASSISTANT",
        groups: ["Trades"],
        description:
          "Hi, I'm the Inventory Management Assistant. I can help you update your inventory, analyze your stock levels, and track your materials. I can also help you ensure that you have the right materials on hand when you need them.",
      },
      17: {
        id: "budget-management",
        image: (
          <img src={tasks_gif} alt="budget management" className="w-[100%]" />
        ),
        mobileImage: (
          <img src={tasks_gif} alt="budget management" className="w-[100%]" />
        ),
        avatar: <img src={worker_2} alt="digital worker" />,
        actions: ["Budget Updates", "Budget Analysis"],
        title: "BUDGET MANAGEMENT ASSISTANT",
        groups: ["Owners", "GCs", "Trades"],
        description:
          "Hello! I'm the Budget Management Assistant. I can help you update your budget, analyze your spending, and track your costs. I can also help you identify potential cost overruns and develop strategies to stay within your budget.",
      },
      18: {
        id: "work-order-management",
        image: (
          <img
            src={lastword_gif}
            alt="work order management"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={lastword_gif}
            alt="work order management"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_3} alt="digital worker" />,
        actions: ["Work Order Tracking", "Work Order Analysis"],
        title: "WORK ORDER MANAGEMENT ASSISTANT",
        groups: ["Trades"],
        description:
          "I'm the Work Order Management Assistant. I can help you track work orders, analyze their status, and ensure that they are completed on time.",
      },
      19: {
        id: "suppliers-management",
        image: (
          <img
            src={integrations_gif}
            alt="supplier management"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={integrations_gif}
            alt="supplier management"
            className="w-[100%]"
          />
        ),
        avatar: <img src={worker_4} alt="digital worker" />,
        actions: [
          "Contract Analysis",
          "Payment Tracking",
          "Performance Tracking",
        ],
        title: "SUPPLIERS MANAGEMENT ASSISTANT",
        groups: ["GCs", "Trades"],
        description:
          "Hi, I'm the Suppliers Management Assistant. I can help you analyze contracts, track payments, and monitor the performance of your suppliers. I can also help you negotiate better terms with your suppliers.",
      },
      20: {
        id: "meetings-assistant",
        image: (
          <img src={details} alt="meeting assistance" className="w-[90%]" />
        ),
        mobileImage: <img src={mobileDetails} alt="meeting assistance" />,
        avatar: <img src={worker_1} alt="digital worker" />,
        actions: [
          "Write and share meeting agenda",
          "Write and share meeting minutes",
          "Meetings scheduling",
          "Extrack and track ation items",
        ],
        title: "MEETINGS ASSISTANT ",
        groups: ["Owners", "GCs", "Trades", "Consultants"],
        description:
          "Hi, I'm your Meetings Assistant! I'm here to help you with all your meeting needs. I can write and share meeting agendas and minutes, schedule your meetings, and even extract and track action items. With me around, you can focus on the discussion and leave the details to me.",
      },
    },
    how_it_works: {
      image: <img src={howitworks} alt="how it works" className="w-[90%]" />,
      steps: {
        1: {
          step: "1",
          description:
            "Flowlly connects to your system of record or scheduling software to retrieve the schedule and directory.",
        },
        2: {
          step: "2",
          description:
            "Users provide information via WhatsApp, SMS, Teams, or email in the form of voice notes, videos, texts, and images.",
        },
        3: {
          step: "3",
          description:
            "If needed, Flowlly requests additional information from the users.",
        },
        4: {
          step: "4",
          description:
            "  Flowlly automatically processes the information and generates reports and schedule impact analysis for review and approval.",
        },
        5: {
          step: "5",
          description:
            "Main user reviews and approves reports and schedule impact.",
        },
        6: {
          step: "6",
          description:
            "   Flowlly syncs reports and schedule updates to systems of record and/or scheduling software.",
        },
      },
    },
    testimonials: {
      1: {
        quote:
          "Our team saved 10 hours per week on admin tasks. Now they spend that time on solving project issues.",
        by: "Project Manager, Vancouver, Canada",
      },
      2: {
        quote:
          "Flowlly has helped us have all of our CYA's documents ready. It has already saved us from a few missunderstandings.",
        by: "Construction Director, Toronto, Canada",
      },
      3: {
        quote:
          "No one really likes doing the meeting minutes and follow ups. Flowlly has been a life changer for us, we can be more engaged and productive in the meeting without worring about taking notes.",
        by: "Project Coordinator, Toronto, Canada",
      },
    },
    partners: {
      1: {
        name: "Microsoft",
        image: <img src={microsoft} alt="microsoft" className="w-[100%]" />,
      },
      2: {
        name: "Google",
        image: <img src={google} alt="google" className="w-[100%]" />,
      },
      3: {
        name: "Formwork Labs",
        image: <img src={formworks} alt="flowly" className="w-[100%]" />,
      },
      4: {
        name: "Antler",
        image: <img src={antler} alt="flowly" className="w-[100%]" />,
      },
    },
  },
};

export default content;
